// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
// Components
import InstructionCard from './components/InstructionCard';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';

const instructions = [
  { title: 'Інструкція до кабінету АЛЛО', link: 'https://docs.google.com/document/d/1GEfJejZbo88aMnQK2mIOXcwUj9-O4YvuGqaPIKirgJU/' },
  { title: 'Графік обробки замовлень', link: 'https://drive.google.com/file/d/1qLlaUb2DcC_b55XuT9I4Wd4hTEayJvLo/' },
  { title: 'Еквайринг Monobank', link: 'https://drive.google.com/file/d/1yOm3k1gk3nA8fKjqbc-ZCXfYFoWNBQ-2/view?usp=sharing' },
  { title: 'Сповіщення про нове замовлення (Viber/SMS)', link: 'https://drive.google.com/file/d/1Mh6FoROFdyd2HoduJkhSk39YBVUPQMzo/' },
  { title: 'Покупка частинами Monobank', link: 'https://drive.google.com/file/d/1u9Z3OMvFAEwtljyTm9_GB8p9RXQYS8yP/view' },
];

function InstructionPage() {
  const { t } = useTranslation();
  const sortedInstructions = [
    instructions[0],
    ...instructions.slice(1).sort((a, b) => a.title.localeCompare(b.title)),
  ];

  return (
    <>
      <PageHeader title={t('Инструкция')} />
      <Box
        sx={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
        }}
      >
        {sortedInstructions.map(instruction => (
          <InstructionCard key={instruction.link} title={instruction.title} link={instruction.link} />
        ))}
      </Box>
    </>
  );
}

export default InstructionPage;
