// Core
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Icons
import FilePdfRedIcon from '../../../icons/FilePdfRedIcon';
// Helpers
import { COLOR } from '../../../containers/App/AppStyles';

function InstructionCard({ title, link }) {
  return (
    <Box
      sx={{
        borderRadius: '5px',
        backgroundColor: `${COLOR.darkStyle.fontColorMain}`,
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: {
          xs: '17.5px 8px',
          md: '20px 9.5px',
          lg: '20px 35px',
        },
        textAlign: 'center',
        width: '100%',
        height: '168px',
        cursor: 'pointer',
      }}
    >
      <FilePdfRedIcon sx={{ width: '45px', height: '60px' }} />
      <Box
        sx={{
          height: {
            xs: '60px',
            sm: '40px',
          },
        }}
      >
        <Typography
          component="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'block',
            color: `${COLOR.blue.main}`,
            fontSize: '14px',
            lineHeight: '20px',
            textEdge: 'cap',
            textDecoration: 'underline',
            '&:hover': {
              color: `${COLOR.blue[85]}`,
              textDecoration: 'underline',
            },
            '&:visited': {
              color: `${COLOR.blue[75]}`,
              textDecoration: 'underline',
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default InstructionCard;
